<template>
  <div>
    <p class="team-members-functions" style="display: none" data-toggle="modal" ref="clickupdateInviteTeam"
      data-target="#updateUniform_Modal">
      <img src="../../../../assets/Svg/ellipse.svg" alt="" />
    </p>

    <!-- UpdateUniformID -->
    <div class="modal fade" id="updateUniform_Modal" role="dialog" aria-labelledby="updateUniform_Modal"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="updateUniform_ModalLabel">
              Edit Name
            </h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalUUpdateUniform">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <input type="text" class="form-control message bg-white" id="fname" v-model="fname" @keyup="errorNull"
                required />
              <span class="placeholder">First Name</span>
            </div>
            <div class="form-group">
              <input type="text" class="form-control message bg-white" id="lname" v-model="lname" @keyup="errorNull"
                required />
              <span class="placeholder">Last Name</span>
            </div>
          </div>

          <div class="event-btns-wrapper px-3 pb-3">
            <button v-if="this.isDisabled == false" type="button" class="btn btn-primary w-100" v-on:click="update(0)"
              :disabled="isLoadingArrayInvites[0]">
              <div v-if="isLoadingArrayInvites[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
            <button v-else-if="this.isDisabled == true" type="button" class="btn btn-primary-disabled w-100"
              :disabled="(true, isLoadingArrayInvites[0])">
              <div v-if="isLoadingArrayInvites[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- UpdateUniformID -->
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "InvitesTeamOption",
  components: {},
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      isLoadingArrayInvites: [],
      showLoader: 0,
      fname: "",
      lname: "",
      targetName: "",
      memberIOptions: false,
      items: [],
    };
  },

  mounted() {
    this.$root.$on("fetchInviteTeamRow", (item) => {

      this.items = item;
      this.fname = this.items.fname != "null" ? this.items.fname : "";
      this.lname = this.items.lname != "null" ? this.items.lname : "";
      this.targetName = this.items.target;
      var elem = this.$refs.clickupdateInviteTeam;
      if (elem) {
        elem.click();
      }
    });
    $(".swal2-confirm").html("Yes");
  },
  methods: {
    // ---- Update ----------------------------------------------------------------------
    async update(index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArrayInvites, index, true);
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("id", this.items.id);
      formData.append("fname", this.fname);
      formData.append("lname", this.lname);

      await axios
        .post(
          process.env.VUE_APP_API_URL + "invite/updateDataInvite",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.isDisabled = false;
          this.$set(this.isLoadingArrayInvites, index, false);
          this.showLoader = 0;
          this.$root.$emit("HitInviteTeamList");
          this.fname = "";
          this.lname = "";
          this.$alert("Information has been updated successfully.");
          var elem = this.$refs.closeModalUUpdateUniform;
          elem.click();
        });
    },

    // ---- Check Null ----------------------------------------------------------------------
    errorNull: function () {
      if (
        (this.fname === "" || this.fname === null) &&
        (this.lname === "" || this.lname === null)
      ) {
        this.isDisabled = true; // Disable only if everything is empty
      } else {
        this.isDisabled = false; // Enable otherwise
      }
    },
  },
};
</script>