<template>
  <div>
    <!-- Report chat Modal -->
    <div
      class="modal fade"
      id="reportChat"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editTeamModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content individualReportModal">
          <div class="modal-header">
            <h6 class="modal-title" id="editTeamModalLabel">Report</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalproblemList"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body individualReportModal">
            <form>
              <div class="select-problem">
                <h5>Please select a problem</h5>
                <p>
                  If someone is in immediate danger, get help before reporting
                  to who i follow. Don't wait.
                </p>
              </div>
              <div class="user-notification d-flex align-items-center justify-content-center" v-if="showLoader == 1">
                <div class="lds-dual-ring-refresh"></div>
              </div>
              <ul v-else class="problem-list">
                <li
                  v-for="reportlist in reportType"
                  :key="reportlist.id"
                  @click="feedbackSelected(reportlist.id, reportlist.title)"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-dismiss="modal"
                  data-keyboard="false"
                  data-target="#ReportDescriptionModal"
                >
                  <div>{{ reportlist.title }}</div>
                  <span>></span>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Report Description Modal -->

    <div
      class="modal fade"
      id="ReportDescriptionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editTeamModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="editTeamModalLabel1">
              Report {{ chatRoomType }} for {{ feedbackCatagroyTitle }}
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalReportchatDetails"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="feedbackResoponse">
            <div class="modal-body">
              <div class="report-problem">
                <label for="description">Enter Description</label>
                <textarea
                  name="Description"
                  id="description"
                  class="w-100"
                  cols="30"
                  rows="5"
                  placeholder="Description (Optional)"
                  v-model="description"
                  maxlength="1000"
                ></textarea>
              </div>
            </div>

            <div class="event-btns-wrapper justify-content-end p-3">
              <button
                v-if="this.isDisabled == false"
                type="submit"
                class="btn btn-primary w-100"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="loading" v-cloak class="lds-dual-ring"></div>
                
                <span v-else class="report-submit">Submit</span>
              </button>
              <button
                v-if="this.isDisabled == true"
                type="button"
                class="btn btn-primary-disabled w-100"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="loading" v-cloak class="lds-dual-ring"></div>
                
                <span v-else class="report-submit">Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <sweet-modal ref="ReportStatusmodal" :icon="reportStatus">
      {{ this.reportStatusMessage }}
    </sweet-modal>
  </div>
</template>

<script>
import axios from "axios";
import { SweetModal } from "sweet-modal-vue";
export default {
  name: "report",
  components: {
    SweetModal,
  },
  data() {
    return {
      isLoadingArray: [],
      isDisabled : false,
      reportType: "",
      description: "",
      feedbackCatagroyId: "",
      feedbackCatagroyTitle: "",
      loading: false,
      validationserror: false,
      reportStatus: "success",
      reportStatusMessage: "",
      showLoader: 1,
    };
  },

  methods: {
    // ====================== FeedBack ======================

    // errorNull: function() {
    //   if(this.description == '') {
    //     this.isDisabled= true;
    //   }
    //   else {
    //     this.isDisabled = false;
    //   }
    // },

    closeModal: function() {
      this.$root.$emit("closeReportOption");
      this.validationserror = false;
      this.chatRoomType = "";
      this.feedbackCatagroyId = "";
      this.chatRoomId = "";
      this.chatUserId = "";
      this.feedbackCatagroyTitle = "";
      this.description = "";
    },
    async feedbackSelected(id, title) {
      this.feedbackCatagroyId = id;
      this.feedbackCatagroyTitle = title;
    },
    // Continue feedback
    async contuinueFeedbackHandler() {
      axios
        .get(process.env.VUE_APP_API_URL + "feedback/types/list?id=2")
        .then((response) => {
          this.reportType = response.data.data;
          this.showLoader = false;
        });
    },

    // Feedback response save
    feedbackResoponse: function(event) {
      this.loading = true;
      setTimeout(
        function() {
          this.loading = false;
        }.bind(this),
        1000
      );
      const formData = new FormData();
      formData.append("category_id", this.feedbackCatagroyId);
      formData.append("chatRoomId", this.chatRoomId);
      formData.append("chatUserId", this.chatUserId);
      formData.append("description", this.description);
      formData.append("roomType", this.chatRoomType);

      axios
        .post(process.env.VUE_APP_API_URL + "feedback/report-store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          if (res.data.statusCode == 400) {
            this.reportStatus = "warning";
          } else if (res.data.statusCode == 200) {
            this.reportStatus = "success";
          }
          this.reportStatusMessage = res.data.message;
          this.$refs.ReportStatusmodal.open();

          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));
          await delay(2000); /// waiting 3 second.
          event.target.reset();
          var elem = this.$refs.closeModalReportchatDetails;
          elem.click();
          this.closeModal();
          // this.isDisabled = true;
        });
    },
  },

  props: {
    chatRoomId: String,
    chatRoomType: String,
    chatUserId: String,
  },

  mounted() {
    this.contuinueFeedbackHandler();
  },
};
</script>

<style scoped>
.select-problem h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #111115;
  margin-bottom: 10px;
}

.select-problem p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(17, 17, 21, 0.4);
  margin-bottom: 20px;
}

.problem-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.problem-list li {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color: #111115;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.problem-list li div {
  word-break: break-all;
  max-width: 560px;
}

#reportChat .close,
#ReportDescriptionModal .close {
  color: #188ef5 !important;
  font-weight: normal;
}

#ReportDescriptionModal .modal-header h6 {
  max-width: 610px;
  word-break: break-all;
}

@media screen and (max-width: 576px) {
  #ReportDescriptionModal .modal-header .modal-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

#reportChat .modal-header h5,
#ReportDescriptionModal .modal-header h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #254c72;
}

.report-problem label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #111115;
  margin-bottom: 20px;
}

.report-problem textarea {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #868585;
  border: 1px solid #868585;
  padding-left: 15px;
  padding-top: 10px;
  border-radius: 5px;
  resize: none;
}

.individualReportModal {
  overflow-y: auto;
  height: 555px;
}

.problem-list li:last-child {
  margin-bottom: 0;
}
</style>
