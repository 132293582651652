<template>
  <div>
    <!-- Update Uniform -->
    <div
      class="modal fade"
      id="addUniform_Modal"
      role="dialog"
      aria-labelledby="addUniform_Modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h6 class="modal-title" id="addUniform_ModalLabel">
             Player Info
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalUniform"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 player-heading">
                <h6>Mark as Player</h6>
                <toggle-button class="m-0"  v-model="isPlayer" :value="false" :sync="true" :width="34"
                @change="updateIsPlayer" />
              </div>
              <div class="col-12 player-tag">Users with player status will appears in game lineup, roster, and for keeping stats.</div>
              <div v-if="isPlayer">
              <div class="col-12">
                <div class="form-group">
                  <label for="uniform">Uniform No#</label>
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="uniform"
                    v-model="uniform"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                    maxlength="2"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="bat_size">Bat Size</label>
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="bat_size"
                    v-model="bat_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"

                    required
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="bat_details">Bat Load</label>
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="bat_details"
                    v-model="bat_details"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="hat_size">Hat Size</label>
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="hat_size"
                    v-model="hat_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="shirt_size">Shirt Size</label>
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="shirt_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="pant_size">Pant</label>
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="pant_size"
                    v-model="pant_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <div class="d-flex align-items-center ml-auto">
              <img
                class="loaderSign"
                style="margin-right: 12px; height: 28px"
                src="../../../../assets/loader.gif"
                v-if="this.showLoader == 1"
              />
              <button
                v-if="this.isDisabled == false"
                type="button"
                class="btn btn-primary"
                block
                v-on:click="update()"
              >
                <span>Save</span>
              </button>
              <button
                v-else-if="this.isDisabled == true"
                type="button"
                class="btn btn-primary"
                :disabled="true"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Uniform modal -->
  </div>
</template>
<script>
// import * as firestoreService from "../../../../database/firestore";
import axios from "axios";
export default {
  name: "AddUniformModal",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  data() {
    return {
      isDisabled: false,
      showLoader: 0,
      uniform: "",
      userChatId: {},
      bat_size: "",
      bat_details: "",
      hat_size: "",
      shirt_size: "",
      pant_size: "",
      isPlayer: false,
    };
  },

  methods: {
    updateIsPlayer(value) {
      console.log('IsPlayer toggled:', value);
      // Toggle visibility of input fields based on `isPlayer`
      
    },
    // ---- Check Null ----------------------------------------------------------------------
    errorNull: function() {
      if (
        this.uniform == "" ||
        this.bat_size == "" ||
        this.bat_details == "" ||
        this.hat_size == "" ||
        this.shirt_size == "" ||
        this.pant_size == ""
      ) {
        this.isDisabled = true;
      }
      else {
        this.isDisabled = false
      }
    },
    updatePlayer() {
      this.playerTag = !this.playerTag
    },
    handleInputChange() {
    // Check if any of the input values have changed
    const isValueChanged =
      this.uniform !== this.initialUniform ||
      this.bat_size !== this.initialBatSize ||
      this.bat_details !== this.initialBatDetails ||
      this.hat_size !== this.initialHatSize ||
      this.shirt_size !== this.initialShirtSize ||
      this.pant_size !== this.initialPantSize;

    // Update the flag accordingly
    this.isDisabled = !isValueChanged;
  },

    // ---- Update ----------------------------------------------------------------------
    async update() {
  try {
    this.isDisabled = true;
    this.showLoader = 1;

    // Make API call to update team member sizes
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}chat/updateTeamMemberSizes`,
      {
        roomId: this.selectedRoomTeam.roomId,
        userId: this.userChatId.member.id,
        uniform: this.uniform,
        bat_details: this.bat_details,
        bat_size: this.bat_size,
        hat_size: this.hat_size,
        shirt_size: this.shirt_size,
        pant_size: this.pant_size,
        isPlayer: this.isPlayer ? 1 : 0,
      }
    );

    // If API call is successful, proceed with Firestore update
    if (response.data.statusCode === 200) {
      
      this.$alert("Player info has been updated successfully.");
      
      // Close the modal
      this.$refs.closeModalUniform.click();
      this.uniform = null;
        this.bat_size = null;
        this.bat_details = null;
        this.hat_size = null;
        this.shirt_size = null;
        this.pant_size = null;
      // Emit events for UI updates
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
      this.$root.$emit("fetchUpdatedTeammates");
    } 
  } catch (error) {
    console.error("Error updating player info:", error);
    this.$alert("Failed to update player info. Please try again.");
  } finally {
    this.showLoader = 0;
    this.isDisabled = false;
  }
}
,
  },
  mounted() {
    this.$root.$on("fetchUniformData", (userChatId) => {
      this.isDisabled = true;
      this.showLoader = 0;
      console.log(userChatId,"jsjjs")
      this.userChatId = userChatId;
      this.uniform = userChatId.uniform_no;
      this.bat_size = userChatId.bat_size;
      this.bat_details = userChatId.bat_details;
      this.shirt_size = userChatId.shirt_size;
      this.pant_size = userChatId.pant_size;
      this.hat_size = userChatId.hat_size;
      this.isPlayer = userChatId.isPlayer === 1;
      this.errorNull();
    });
  },
};
</script>
