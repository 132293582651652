<template>
    <div>
      <p 
      class="team-members-functions" 
        style="display: none" 
        data-toggle="modal" 
        ref="clickupdateInviteTeam"
        data-target="#updatePlayerInfo"
      >
        <img src="../../../../assets/Svg/ellipse.svg" alt="" />
      </p>
  
      <!-- UpdateUniformID -->
      <div 
        class="modal fade" 
        id="updatePlayerInfo" 
        role="dialog" 
        aria-labelledby="updatePlayerInfo"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" id="updatePlayerInfoLabel">
                Player Info.
              </h6>
              <button 
                type="button" 
                class="close" 
                data-dismiss="modal" 
                aria-label="Close" 
                ref="closeModalUUpdateUniform"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-12 d-flex justify-content-between player-heading pb-2 px-0">
                <h6>Mark as Player</h6>
                <toggle-button class="m-0"  v-model="isPlayer" :value="false" :sync="true" :width="34"
                  @change="updateIsPlayer"  />
              </div>
              <!-- <div class="form-group">
                <input 
                  type="text" 
                  class="form-control message bg-white" 
                  id="fname" 
                  v-model="fname" 
                  @keyup="errorNull"
                  required 
                />
                <span class="placeholder">First Name</span>
              </div>
              <div class="form-group">
                <input 
                  type="text" 
                  class="form-control message bg-white" 
                  id="lname" 
                  v-model="lname" 
                  @keyup="errorNull"
                  required 
                />
                <span class="placeholder">Last Name</span>
              </div> -->
              <div class="form-group" v-if="isPlayer">
                <input 
                  type="text" 
                  class="form-control message bg-white" 
                  id="uniform" 
                  v-model="uniform" 
                  @keyup="errorNull"
                  required 
                />
                <span class="placeholder">Uniform No#</span>
              </div>
            </div>
  
            <div class="event-btns-wrapper px-3 pb-3">
              <button 
                v-if="this.isDisabled == false" 
                type="button" 
                class="btn btn-primary w-100" 
                v-on:click="update(0)"
                :disabled="isLoadingArrayInvites[0]"
              >
                <div v-if="isLoadingArrayInvites[0]" class="lds-dual-ring"></div>
                <span v-else>Proceed</span>
              </button>
              <button 
                v-else-if="this.isDisabled == true" 
                type="button" 
                class="btn btn-primary-disabled w-100"
                :disabled="(true, isLoadingArrayInvites[0])"
              >
                <div v-if="isLoadingArrayInvites[0]" class="lds-dual-ring"></div>
                <span v-else>Proceed</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- UpdateUniformID -->
    </div>
  </template>
  <script>
  import axios from "axios";
  import $ from "jquery";
  
  export default {
    name: "PlayerInfo",
    components: {},
    data() {
      return {
        isDisabled: true,
        isLoadingArray: [],
        isLoadingArrayInvites: [],
        showLoader: 0,
        uniform: "",
        // fname: "",
        // lname: "",
        targetName: "",
        memberIOptions: false,
        items: [],
        isPlayer: false,
  
      };
    },
  
    mounted() {
      this.$root.$on("fetchInvitePlayerInfo", (item) => {
  
        this.items = item;
        this.isPlayer= this.items?.team_members?.isPlayer === 1
        // this.fname = this.items.fname != "null" ? this.items.fname : "";
        this.uniform = this.items.uniform != "null" ? this.items.uniform : "";
        // this.lname = this.items.lname != "null" ? this.items.lname : "";
        this.targetName = this.items.target;
        var elem = this.$refs.clickupdateInviteTeam;
        if (elem) {
          elem.click();
        }
      });
      $(".swal2-confirm").html("Yes");
    }, 
    methods: {
      updateIsPlayer(value) {
        this.errorNull();
        console.log('IsPlayer toggled:', value);
  
      },
      // ---- Update ----------------------------------------------------------------------
      async update(index) {
        this.isDisabled = true;
        this.$set(this.isLoadingArrayInvites, index, true);
        this.showLoader = 1;
        var formData = new FormData();
        formData.append("id", this.items.id);
        // formData.append("fname", this.fname);
        // formData.append("lname", this.lname);
        formData.append("is_player", this.isPlayer ? 1 : 0);
        formData.append("uniform", this.uniform);
  
        await axios
          .post(
            process.env.VUE_APP_API_URL + "invite/updateDataInvite",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.isDisabled = false;
            this.$set(this.isLoadingArrayInvites, index, false);
            this.showLoader = 0;
            this.$root.$emit("HitInviteTeamList");
            // this.fname = "";
            // this.lname = "";
            this.uniform = "";
            this.$alert("Information has been updated successfully.");
            var elem = this.$refs.closeModalUUpdateUniform;
            elem.click();
          });
      },
  
      // ---- Check Null ----------------------------------------------------------------------
      errorNull: function () {
        if (
          // (this.fname === "" || this.fname === null) &&
          // (this.lname === "" || this.lname === null) &&
          (this.isPlayer && (this.uniform === "" || this.uniform === null)) // Only check uniform if isPlayer is true
        ) {
          this.isDisabled = true; // Disable only if everything is empty
        } else {
          this.isDisabled = false; // Enable otherwise
        }
      },
  
    },
  };
  </script>