<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FriendsInvitation"
      ref="openModalInviteToTeam"
    ></button>

    <div class="modal fade" tabindex="-1" role="dialog" id="FriendsInvitation">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Invite Friend</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              ref="closeModalMembers"
              aria-label="Close"
            >
              <img src="../../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <p v-if="errorMessage != null" class="tag-line" style="color: red">
              {{ errorMessage }}
            </p>
            <p v-else class="tag-line mb-3">Let's invite your friends.</p>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="home-tab"
                  ref="mydetailTab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >Via Link</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  ref="locationTabGet"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >Via Mobile/Email</a
                >
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="friends-tab"
                  data-toggle="tab"
                  href="#friends"
                  ref="friends"
                  role="tab"
                  aria-controls="friends"
                  aria-selected="false"
                  >Add Friends</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active mt-3 text-center"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div
                  class="lds-dual-ring-refresh"
                  v-if="showLinkLoader == 0"
                ></div>
                <div v-if="showLinkLoader == 1 && linkTobe">
                  <form>
                    <div class="form-group add-link">
                      <input
                        id="link"
                        required
                        ref="clone"
                        type="text"
                        class="mb-3 form-control d-block"
                        v-model="urlLinkCopy"
                        readonly
                        v-on:keyup="errorNull"
                      />
                    </div>

                    <div class="event-btns-wrapper justify-content-end">
                      <button
                        @click.stop.prevent="copyUrl"
                        class="btn main-btn w-100"
                        ref="btnToggle"
                      >
                        Copy Link
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                class="tab-pane fade mt-3"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div class="add-link">
                  <form>
                    <div class="d-md-flex align-items-center mt-2">
                      <div class="phone-code mb-2">
                        <b-form-select
                          v-model="selectedCode"
                          :options="codeList"
                        >
                          <template #first> </template>
                        </b-form-select>
                      </div>

                      <div class="form-group phone-number">
                        <div class="input-wrapper">
                          <input
                            id="mob"
                            class="form-control"
                            v-model.trim="$v.details.mob.$model"
                            spellcheck="false"
                            autocomplete="off"
                            :class="{
                              'is-invalid': validationStatus($v.details.mob),
                            }"
                            trim
                            type="text"
                            v-on:keyup="errorNull"
                            required
                          />

                          <span class="placeholder">Mobile Number</span>
                          <div
                            v-if="!$v.details.mob.integer"
                            class="invalid-tooltip"
                          >
                            Mobile number should be numbers.
                          </div>
                          <div
                            v-else-if="!$v.details.mob.minLength"
                            class="invalid-tooltip"
                          >
                            Minimum length should be 10.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mb-2">OR</div>

                    <div class="form-group">
                      <div class="input-wrapper">
                        <input
                          id="email"
                          v-model.trim="$v.details.email.$model"
                          spellcheck="false"
                          autocomplete="off"
                          :class="{
                            'is-invalid': validationStatus($v.details.email),
                          }"
                          trim
                          required
                          class="form-control"
                          type="text"
                          v-on:keyup="errorNull"
                        />

                        <span class="placeholder">Email</span>
                        <div
                          v-if="!$v.details.email.email"
                          class="invalid-tooltip"
                        >
                          Please provide valid email
                        </div>
                      </div>
                    </div>
                  </form>
                  <form>
                    <div class="d-md-flex align-items-center mb-2 mt-4">
                      <p class="tag-line w-100 w-md-25">Select User Role:</p>
                      <div class="phone-code w-100 w-md-75 mr-0">
                        <b-form-select v-model="roleType" :options="roleList">
                          <template #first> </template>
                        </b-form-select>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="event-btns-wrapper justify-content-end">
                  <button
                    v-if="isDisabled == false && showLoaderSend == 0"
                    type="button"
                    class="btn btn-primary w-100"
                    :disabled="isLoadingArray[0]"
                    @click="sendInvites(0)"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Send</span>
                  </button>
                  <button
                    v-else-if="isDisabled == true || showLoaderSend == 1"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="(true, isLoadingArray[0])"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Send</span>
                  </button>
                </div>
              </div>
              <div
                class="tab-pane fade mt-3"
                id="friends"
                role="tabpanel"
                aria-labelledby="friends-tab"
              >
                <form class="add-link">
                  <div class="member-select mt-2">
                    <label class="typo__label" for="ajax">Select friends</label>
                    <multiselect
                      v-model="selectedUsers"
                      id="ajax"
                      label="fname"
                      track-by="id"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="addMultipleUsers"
                      :multiple="true"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :options-limit="300"
                      :limit="100"
                      :limit-text="limitText"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                      @input="errorNullMember"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <div class="custom__content">
                            <div class="user-image">
                              <img
                                v-if="option.picture !== 'yes'"
                                src="../../../../assets/images/user-default-new.png"
                                alt=""
                              />
                              <img
                                v-else
                                :src="
                                  API_URL +
                                  option.id +
                                  '/profile_picture.jpg?v=' +
                                  time
                                "
                                alt=""
                                style="
                                  width: 40px;
                                  height: 40px;
                                  background-size: cover;
                                  background-repeat: no-repeat;
                                  background-position: center;
                                  object-fit: cover;
                                  border-radius: 100px;
                                "
                              />
                            </div>
                            <span class="user__name">
                              {{ option.user_name }}</span
                            >
                          </div>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      >
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="selectedUsers.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div> </template
                      ><span slot="noResult"
                        >Oops! No elements found. Consider changing the search
                        query.</span
                      >
                      <span slot="noOptions"> No User Found </span>
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <div class="user-image">
                            <img
                              v-if="option.picture !== 'yes'"
                              src="../../../../assets/images/user-default-new.png"
                              alt=""
                            />
                            <img
                              v-else
                              :src="
                                API_URL +
                                option.id +
                                '/profile_picture.jpg?v=' +
                                time
                              "
                              alt=""
                              style="
                                width: 40px;
                                height: 40px;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                object-fit: cover;
                                border-radius: 100px;
                              "
                            />
                          </div>
                          &nbsp;<span class="text-capitalize">
                            {{ option.user_name }}</span
                          >
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </form>
                <div class="event-btns-wrapper justify-content-end">
                  <button
                    v-if="isDisabledMmeber == false"
                    type="button"
                    class="btn btn-primary w-100 mt-3"
                    :disabled="isLoadingArrayMember[0]"
                    @click="addMembers(0)"
                  >
                    <div
                      v-if="isLoadingArrayMember[0]"
                      class="lds-dual-ring"
                    ></div>
                    <span v-else>Add</span>
                  </button>
                  <button
                    v-else-if="isDisabledMmeber == true"
                    type="button"
                    class="btn btn-primary-disabled w-100 mt-3"
                    :disabled="(true, isLoadingArrayMember[0])"
                  >
                    <div
                      v-if="isLoadingArrayMember[0]"
                      class="lds-dual-ring"
                    ></div>
                    <span v-else>Add</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, minLength, integer } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import axios from "axios";
import * as firestoreService from "../../../../database/firestore";
export default {
  name: "InviteToTeamModal",
  data: function () {
    return {
      errorMessage: null,
      showLinkLoader: 0,
      linkTobe: false,
      selectedCode: "+1",
      userProfiles:[],
      urlLinkCopy: "",
      details: {
        code: "+1",
        mob: "",
        email: "",
        role: "0",
        grpID: this.selectedRoomTeam.roomId,
        grpImage: this.selectedRoomTeam.teamAvatar,
        grpName: this.selectedRoomTeam.teamName,
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
      roleType: "0",
      roleList: [
        { value: "0", text: "Teammate" },
        { value: "2", text: "Fan" },
      ],
      isDisabled: true,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      showLoaderSend: 0,
      successMessage: null,
      isLoadingArray: [],
      isLoadingArrayMember: [],
      addMultipleUsers: [],
      selectedUsers: [],
      isDisabledMmeber: true,
      showLoaderMember: 0,
      isLoading: false,
      teamSelectedUser: [],
      teamUserUncount: [],
      teamSelectedUserRole: [],
      localteamSelected: [],
      allUsersRef: [],
      roomId: this.selectedRoomTeam.roomId,
    };
  },
  validations: {
    details: {
      email: { email },
      mob: { integer, minLength: minLength(10) },
    },
  },
  components: {
    Multiselect,
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    limitText(count) {
      return `and ${count} others`;
    },
    async addMembers(index) {
  if (this.selectedUsers.length > 0) {
    try {
      this.showLoaderMember = 1;
      this.isDisabledMmeber = true;
      this.$set(this.isLoadingArrayMember, index, true);
      this.teamSelectedUser = [];
      this.teamSelectedUserRole = [];
      this.teamUserUncount = [];
      this.teamSelectedUserRole = this.selectedRoomTeam.userRole;

      for (const userList of this.selectedRoomTeam.users) {
        this.teamSelectedUser.push(userList.id);
      }

      // Create User if not exist
      const rawUsers = [];
      const promise = await firestoreService.getAllUsers();
      rawUsers.push(promise);

      this.allUsersRef = await Promise.all(rawUsers);

      for (const user of this.selectedUsers) {
        for (const usersR of this.allUsersRef) {
          usersR.data.forEach((dataU) => {
            if (dataU.c_id == user.id) {
              this.foundSentUser = dataU;
            }
          });
        }

        if (this.foundSentUser) {
          this.teamSelectedUser.push(this.foundSentUser.id);

          // Add admin roles for non-admin
          const arrayAdminUser = {
            id: this.foundSentUser.id,
            user_id: user.id, // New field added
            admin: "0",
            notification: "on",
            pushNotification: "on",
            status: 1,
          };

          this.teamSelectedUserRole.push({
            id: this.foundSentUser.id,
            admin: "0",
            notification: "on",
            pushNotification: "on",
            status: 1,
          });

          this.localteamSelected.push(arrayAdminUser);
        }
      }
      this.teamSelectedUser.forEach((userId) => {
          const checkCount = {
            id: userId,
            count: 0,
          };
          this.teamUserUncount.push(checkCount);
        });

      // Call API first
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}chat/storeNewAddedTeamMember`,
        {
          team_id: this.selectedRoomTeam.roomId, // Firestore Room ID
          users: this.localteamSelected,
        }
      );

      // If API is successful, proceed with Firebase and notifications
      if (response.data.statusCode ===200) {
        await firestoreService.updateRoom(this.selectedRoomTeam.roomId, {
          users: this.teamSelectedUser,
          userRole: this.teamSelectedUserRole,
          userCount: this.teamUserUncount,
        });

        // Send notification
        const formData = {
          team_id: this.selectedRoomTeam.roomId,
          users_id: this.selectedUsers,
          message_type: 13,
        };

        await axios.post(
          process.env.VUE_APP_API_URL +
            "notification/sendAddIntoTeamNotification",
          formData
        );
      } else {
        throw new Error("API request failed, stopping further actions.");
      }

      
      this.showLoaderMember = 0;
      this.$set(this.isLoadingArrayMember, index, false);
      this.isDisabledMmeber = false;

      var elem = this.$refs.closeModalMembers;
      elem.click();
      this.teamSelectedUser = [];
      this.teamSelectedUserRole = [];
      this.teamUserUncount = [];
      this.$root.$emit("fetchUpdatedTeammates");
      this.$root.$emit("fetchEvents");
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    } catch (error) {
      console.error("Error adding members:", error);
      this.showLoaderMember = 0;
      this.$set(this.isLoadingArrayMember, index, false);
      this.isDisabledMmeber = false;
      alert("Something went wrong");
    }
  }
}
,
    asyncFind(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}friends/friendList?search=${
            searchQuery ? searchQuery : ""
          }`,
          this.details,
          {}
        )
        .then((response) => {
          this.addMultipleUsers = response.data.data.filter((array) =>
            this.selectedRoomTeam.users.every(
              (filter) => !(filter.c_id == array.id)
            )
          );
          this.isLoading = false;
        });
    },
    errorNull: function () {
      this.errorMessage = null;
      this.successMessage = null;
      if (
        (this.details.mob == "" && this.details.email == "") ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    errorNullMember() {
      if (this.selectedUsers.length > 0) {
        this.isDisabledMmeber = false;
      } else {
        this.isDisabledMmeber = true;
      }
    },
    copyUrl() {
      let testingCodeToCopy = document.querySelector("#link");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        console.log(successful);
        this.$refs.btnToggle.innerText = this.show ? "Copy" : "Copied";
      } catch (err) {
        alert("Oops, unable to copy");
      }
    },
    getUrlLink() {
      this.details.mob = "";
      this.details.email = "";
      this.details.code = "+1";
      this.details.role = "0";
      this.successMessage = null;
      this.errorMessage = null;
      this.isDisabled = true;
      this.isLoading = true;
      this.showLinkLoader = 0;
      var formData = new FormData();
      formData.append("group_id", this.selectedRoomTeam.roomId);
      formData.append("group_name", this.selectedRoomTeam.teamName);
      formData.append("group_image", this.selectedRoomTeam.teamAvatar);

      return axios
        .post(process.env.VUE_APP_API_URL + "invite/getGrpLink", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.showLinkLoader = 1;
            // document.getElementById("link").style.display = "block";
            this.urlLinkCopy = response.data.data;
            this.linkTobe = true;
          } else {
            this.showLinkLoader = 1;
            this.errorMessage = response.data.message;
          }
          this.isLoading = false;
        });
    },
    clearAll() {
      this.selectedUsers = [];
      this.teamSelectedUser = "";
    },
    sendInvites(index) {
      this.details.role = this.roleType;
      this.details.code = this.selectedCode;
      const mobileExists = this.userProfiles.some(
        profile => profile?.user_profile?.mob === this.details?.mob && profile?.user_profile?.mob_code === this.selectedCode
      );

      // Check if email exists
      const emailExists = this.userProfiles.some(
        profile => profile?.user_profile?.email === this.details.email
      );

      // If either mobile or email exists, show error message
      if (mobileExists || emailExists) {
        this.$alert('This user is already a member of this team.');
        return; // Stop further execution
      }
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.showLoaderSend = 1;
      console.log(this.details,"ahshshsl")
      var formData = new FormData();
      formData.append("grpID", this.selectedRoomTeam.roomId);
      formData.append("grpName", this.selectedRoomTeam.teamName);
      formData.append("grpImage", this.selectedRoomTeam.teamAvatar);
      formData.append("role", this.details.role);
      formData.append("code", this.details.code);
      formData.append("email", this.details.email);
      formData.append("mob", this.details.mob);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "invite/grpEmailInvite",
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.details.mob = "";
            this.details.email = "";
            this.details.code = "+1";
            this.details.role = "0";
            this.roleType = "0";
            this.selectedCode = "+1";
            this.successMessage = response.data.message;
            this.$fire({
              text: this.successMessage,
              type: "success",
              showConfirmButton: false,
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
            this.isDisabled = true;
            this.showLoaderSend = 0;
          } else {
            this.errorMessage = response.data.message;
          this.$fire({
            text: this.errorMessage,
            type: "error",
            showConfirmButton: false,
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
          this.showLoaderSend = 0;
          }
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            var elem = this.$refs.closeModalMembers;
            elem.click();
            this.$root.$emit("gameTimeScheduledReload");
            this.$root.$emit("gameTimeTeammatesReload");
            this.$root.$emit("gameTimeStatisticsReload");
            this.$root.$emit("HitInviteTeamList");
          }, 500);
        })
        .catch(() => {
          this.details.email = "";
          this.showLoaderSend = 0;
          this.$alert("Failed to send the invitation.Please try again later.");
          this.$set(this.isLoadingArray, index, false);
        })
        .finally(() => {
          this.$set(this.isLoadingArray, index, false);
        });
    },
  },
  mounted() {
    this.$root.$on('userProfileData', (userProfiles) => {
      this.userProfiles= userProfiles;
      console.log(this.userProfiles,"jsjs")
  });
    this.$root.$on("openInviteToTeamModal", () => {
      var elem = this.$refs.openModalInviteToTeam;
      if (elem) {
        elem.click();
        this.getUrlLink();
        this.asyncFind();
      }
    });
  },
};
</script>

<style>
</style>