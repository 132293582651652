<template>
  <div>
    <!-- Create Chat -->
    <div
      class="modal fade"
      id="individualModalDirect"
      role="dialog"
      aria-labelledby="individualModalDirect"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="individualModalDirectLabel">
              New Chat
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalIndividualDirect"
            >
              <img src="../../../../assets/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group direct-msg">
              <input
                type="text"
                class="form-control message bg-white"
                id="sentUserName"
                v-model="sentUserName"
                aria-describedby="emailHelp1"
                readonly
              />
            </div>
            <div class="form-group">
              <textarea
                type="text"
                class="form-control message"
                required
                rows="5"
                placeholder="Message"
                id="individualMessage"
                v-model="individualMessage"
                @input="(e) => (individualMessage = e.target.value)"
                @keyup="errorNullIndividual()"
              ></textarea>
            </div>
          </div>
          <div class="event-btns-wrapper px-3 pb-3 pt-0 justify-content-end">
            <button
              v-if="this.isDisabledIndividual == false"
              type="button"
              class="btn btn-primary w-25"
              block
              v-on:click="createChat(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
            <button
              v-else-if="this.isDisabledIndividual == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Create chat modal -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as firestoreService from "../../../../database/firestore";
import $ from "jquery";
import axios from "axios";

export default {
  name: "DirectMessageModal",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  props: {
    type: {
      type: String,
    },
    userId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isDisabledIndividual: true,
      isLoadingArray: [],
      showIndividual: 1,
      individualMessage: "",
      sentUserId: "",
      sentUserName: "",
      currentUserChatId: "",
      currentUserName: localStorage.getItem("userName"),
      chatRoomId: "",
    };
  },

  methods: {
    ...mapActions("team", ["createIndividualChatAction"]),

    // ---- Send Message ----------------------------------------------------------------------
    errorNullIndividual: function () {
      if (this.individualMessage == "" && this.sentUserName) {
        this.isDisabledIndividual = true;
      } else {
        this.isDisabledIndividual = false;
      }
    },

    // ---- Individual ----------------------------------------------------------------------
    async createChat(index) {
      this.isDisabledIndividual = true;
      this.$set(this.isLoadingArray, index, true);
      try {
      const promiseUser = await firestoreService.getUserCid(
        this.user.id.toString()
      );
      this.currentUserChatId = promiseUser.data[0].id;
      console.log("promiseUser", promiseUser)
      console.log("currentUserChatId", this.currentUserChatId)
      if (this.currentUserChatId !== "" && this.sentUserName) {
        console.log("if")
      const isBlocked = await this.checkBlockUser(this.sentUserName, this.sentUserId);
            if (isBlocked) {
              console.log("isBlocked")
              this.$alert(
                "You are not allowed to send direct message to " +
                  this.sentUserName
              );
            } else {
              console.log("not blocked")
              const chatData = [];
              const selectedIndUsers = [];
              selectedIndUsers["user_name"] = this.sentUserName;
              selectedIndUsers["id"] = this.sentUserId;
              chatData["currentUserId"] = this.currentUserChatId;
              chatData["currentUserName"] = this.currentUserName;
              chatData["currentUserCid"] = localStorage.getItem("userId");
              chatData["selectedIndUsers"] = selectedIndUsers;
              chatData["individualMessage"] = this.individualMessage;
              console.log("chatData", chatData)

        // Wait for createIndividualChatAction to return chatRoomId
        this.chatRoomId = await this.createIndividualChatAction(chatData);

                let window_width = window.innerWidth;
                if (window_width <= 991.98) {
                  $(".chat-right").hide();
                  $(".vac-col-messages").show();
                  this.$root.$emit("showIndividualListActive");
                  this.$root.$emit("showIndividualList");
                } else {
                  this.$root.$emit("showIndividualListActive");
                  this.$root.$emit("showIndividualList");
                }

                // if (this.type !== "chat") {
                //   localStorage.setItem("openTabChat", "Individual");
                //   this.$router.push({ name: "Teams" });
                // }
            }
            console.log("before modal closing")
            this.individualMessage = "";
              var elem = this.$refs.closeModalIndividualDirect;
              if (elem) {
                elem.click();
              }
              console.log("after modal closing")
              this.$fire({
  title: "Would you like to redirect to that chat or stay here?",
  showCancelButton: true,
  confirmButtonText: "Go to ifollow",
  cancelButtonText: "Stay here",
  icon: "warning"
}).then((result) => {
  console.log("Dialog result:", result); // Debugging output

  if (result.dismiss === "cancel" || result.dismiss === "backdrop") {
    console.log("User chose to stay, not redirecting.");
    return; // Prevents redirection
  }

  localStorage.setItem("openTabChat", "Individual");
  // Redirect only if "Go to iFollow" is clicked
  this.$router.push({ path: `/chat/user/${this.chatRoomId}` });
});
      } else {
        console.log("else")
        alert("Something went wrong");
      }
     } catch (error) {
      console.log("in catch")
       alert("Something went wrong");
      } finally {
        this.isDisabledIndividual = true;
        this.$set(this.isLoadingArray, index, false);
      }
    },

    async checkBlockUser(name, userID) {
      return new Promise((resolve, reject) => {
        this.showLoader = 1;
        var formData = new FormData();
        formData.append("blocked_user_id", userID);
        axios
          .post(
            process.env.VUE_APP_API_URL + "block/checkBlockUser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.blockUserFlag = response.data.data.user_blocked;
            if (this.blockUserFlag === 0) {
              console.log("User not blocked");
              resolve(false); // User is not blocked
            } else if (this.blockUserFlag === 1) {
              resolve(true); // User is blocked
            } else {
              reject("Block user check failed");
            }
            console.log("block function completed")
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mounted() {
    this.$root.$on("checkUpdatePost", (item) => {
      this.items = item;
      this.fetchData(this.items);
    });

    this.$root.$on("fetchDirectMessageData", (name, id) => {
      // this.checkBlockUser(name, id);
      this.individualMessage = "";
      this.isDisabledIndividual = true;
      this.showIndividual = 1;
      this.sentUserName = name;
      console.log("sentUserName", this.sentUserName)
      this.sentUserId = id;
    });
  },
};
</script>
