<template>
    <div class="mates-info">
      <div class="mates-image-wrap">
        <div
          class="mates-image position-relative"
          v-for="user in members.slice(0, 6)"
          :key="user.id"
        >
          <abbr style="width: 100%" :title="user.fname">
          <div class="users-notification" v-if="imgLoader == 1">
            <img src="../../../assets/loader.gif" alt="" />
          </div>
          <img
              @load="onImageLoad"
              :src="$userProfileStorageURL + user.member_id +'/profile_picture.jpg?v='+$time"
              onerror="this.src='https://whoifollow.s3.us-west-2.amazonaws.com/chat/groupAvatar/LWE6E3CLzQZkgr56QyEmuCp8IHhut4ZddQa8qzFM.svg'"  
            alt=""
          />
          </abbr>
        </div>
      </div>
      <span v-if="members.length > 6">+{{ members.length - 6 }} more going</span>
      <span v-else-if="members.length >= 1">going</span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      members: {
        type: Array,
      },
    },
    data() {
      return {
        imgLoader: 1,
      };
    },
    methods: {
      onImageLoad() {
        this.imgLoader = 0;
      },
    },
  };
  </script>
  
  <style scoped>
  .mates-info h6{
    font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      color: #557491;
  }
  abbr[data-original-title], abbr[title] {
    cursor: pointer!important;
  }
  </style>