<template>
  <div>
    <InvitesTeamOption v-if="currentIsAdmin == '1'" />
    <PlayerInfo v-if="currentIsAdmin == '1'" />
    <div v-if="showInvitesShimmer == 1">
      <div class="row no-gutters">
        <div class="col-12 p-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
      </div>
    </div>

    <div v-else-if="invitedMembers.length > 0" class="p-2">
      <div class="team-member">
        <h6>Invited</h6>
        <span class="nav-counter-team mt-0">{{ invitedMembers.length }}</span>
      </div>
      <div class="team-members p-2 position-relative">
        <div class="team-info" v-for="user in invitedMembers" :key="user.id">
          <div class="team-details border-0">
            <div class="team-image">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img @load="onImageLoad" :src="getUserImageUrl(user)" @error="handleImageError($event)" alt="" />
            </div>
            <div class="shirt-no" v-if="user?.team_members?.isPlayer && selectedRoomTeam.teamType == 'sports'">
              <img src="../../../../assets/Svg/shirt.svg" alt="Image" />
              <span>{{ user.team_members?.uniform_no || '-' }}</span>
            </div>
            <div class="team-name-role">
              <h6 class="text-lowercase mb-2"> {{ formatPhoneNumber(user.target) }}</h6>
              <p v-if="user.level == '1'">
                <span v-if="(user.fname && user.fname.trim() !== '') || (user.lname && user.lname.trim() !== '')"
                  class="mr-0">
                  {{ user.fname }} {{ user.lname }} -
                </span>
                Teammate
              </p>
              <span v-if="user.level == '1' && user.level == '2'"> - </span>
              <p v-if="user.level == '2'">
                <span v-if="(user.fname && user.fname.trim() !== '') || (user.lname && user.lname.trim() !== '')"
                  class="mr-0">
                  {{ user.fname }} {{ user.lname }} -
                </span> Fan
              </p>
            </div>
          </div>
          <span 
            v-if="currentIsAdmin == '1'"
            class="team-members-functions" 
            @click.stop="openInvitesOptions(user.id)"
            v-click-outside="onClickOutside">
            <img src="../../../../assets/Svg/ellipse.svg" alt="" />
          </span>

          <div class="sub-setting-options" v-if="inviteOptions[user.id]">
            <ul class="all-options">
              <li @click="playerInfo(user)" v-if="selectedRoomTeam.teamType == 'sports'">Player info</li>
              <li @click="fetchData(user)">Edit Name</li>
              <li @click="reinvite(user)">Re-send invite</li>
              <div class="remove-sec">
                <li class="border-0" @click="deleteInvite(user)">Delete</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import axios from "axios";
import InvitesTeamOption from "../ChatSettingComponents/InvitesTeamOption.vue";
import PlayerInfo from "../ChatSettingComponents/PlayerInfo.vue";
export default {
  name: "TeamInviteList",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  components: {
    InvitesTeamOption,
    PlayerInfo
  },
  data() {
    return {
      imgLoader: 1,
      $time: new Date().getTime(),
      $userProfileStorageURL: 'https://api.whoifollow.tech/api/public/storage/users/',
      invitedMembers: [],
      showInvitesShimmer: 0,
      API_URL: this.$userProfileStorageURL,
      // inviteOptions: false,
      inviteOptions: {}, 
      items: []
    };
  },

  mounted() {
    this.getInvitesList();
    this.$root.$on("HitInviteTeamList", () => {
      this.getInvitesList();
    });
  },

  methods: {
    openInvitesOptions(userId) {
    // Close all other options first
      this.inviteOptions = {}; 
      this.$set(this.inviteOptions, userId, true);
    },
    onClickOutside() {
      this.inviteOptions = {}; // Close all options
    },
    getUserImageUrl(user) {
      const imageUrl = user.c_id
        ? `${this.$userProfileStorageURL}${user.user_link}/profile_picture.jpg?v=${this.$time}`
        : 'https://api.whoifollow.tech/user-default2.png';
      return imageUrl;
    },
    onImageLoad(event) {
      console.log('Image loaded with src:', event.target.src); // Log the src when the image loads
      this.imgLoader = 0;
    },
    formatPhoneNumber(number) {
  if (!number) return '';

  // Check for +1 (USA/Canada) format
  const usCanadaRegex = /^\+1\d{10}$/;
  if (usCanadaRegex.test(number)) {
    return number.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '+1 ($1) $2-$3');
  }

  // Check for +92 (Pakistan) format with brackets
  const pakistanRegex = /^\+92\d{10}$/;
  if (pakistanRegex.test(number)) {
    return number.replace(/^\+92(\d{3})(\d{3})(\d{4})$/, '+92 ($1) $2-$3');
  }

  // If it's neither +1 nor +92, return as is
  return number;
}
,

    handleImageError(event) {
      event.target.src = 'https://whoifollow.s3.us-west-2.amazonaws.com/chat/groupAvatar/LWE6E3CLzQZkgr56QyEmuCp8IHhut4ZddQa8qzFM.svg';
    },

    fetchData(item) {
      this.$root.$emit("fetchInviteTeamRow", item);
    },
    playerInfo(item) {
      console.log(item)
      this.$root.$emit("fetchInvitePlayerInfo", item);
    },
    async getInvitesList() {
      this.showInvitesShimmer = 1;
      var formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.roomId);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "invite/inviteTeamListing",
          formData
        )
        .then((response) => {
          this.invitedMembers = response.data.data;
          this.showInvitesShimmer = 0;
        });
    },
    async deleteInvite(user) {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      var formData = new FormData();
      formData.append("id", user.id);
      formData.append("target", user.target);
      await axios
        .post(process.env.VUE_APP_API_URL + "invite/deleteInvite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$root.$emit("HitInviteTeamList");
          this.fname = "";
          this.lname = "";
          this.uniform = "";
          loader.hide();
          this.$alert("Invitation has been deleted successfully.");
        });
    },
    async reinvite(user) {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      var formData = new FormData();
      formData.append("id", user.id);
      formData.append("target", user.target);
      await axios
        .post(process.env.VUE_APP_API_URL + "invite/reInvite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$root.$emit("HitInviteTeamList");
          this.fname = "";
          this.lname = "";
          this.uniform = "";
          loader.hide();
          this.$alert("Invitation has been re-sent successfully.");
        });
    },
  },
};
</script>
<style>
.loaderSign {
  width: 45px !important;
  margin-top: 0px !important;
}

.team-invite-links {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(229, 226, 226);
  margin-bottom: 10px;
  /* padding-bottom: 10px; */
  color: #188ef5;
}

.team-invite-links h6 {
  color: #188ef5;
  font-weight: 600;
  font-size: 15px;
}

.invited-info .team-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.invited-info .team-image {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f6;
}

.team-image .user-default {
  width: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.team-image img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.team-detail-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.invited-function img {
  width: 20px;
  cursor: pointer;
}
</style>
