<template>
  <div>
    <InviteToTeamModal :currentUserId="currentUserId" :selectedRoomTeam="selectedRoomTeam" />
    <div class="row no-gutters mb-2 p-2 bg-white" v-if="showLoader == 1">
      <div class="col-12 p-0">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
      <div class="col-12 p-0 my-2">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
      <div class="col-12 p-0 mb-2">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
    </div>

    <div v-else>
      <div class="teammates-top-layer" :class="['sticky-header', { scrolled: isScrolled }]">
        <div class="team-member member-infos-sec" v-if="!showFilterContainer">
          <h6>
            {{ filteredUserProfile.length === 1 ? "Teammate" : "Teammates" }}
          </h6>
          <span class="nav-counter-team">{{ filteredUserProfile.length }}</span>
        </div>
        <div class="team-member member-infos-sec" v-else>
          <h6>
            {{ filteredUserProfile.length }}
            {{ filteredUserProfile.length === 1 ? "Teammate" : "Teammates" }}
          </h6>
        </div>
        <div class="d-flex align-items-center flex-wrap" style="gap: 10px">
          <div class="search-container">
            <form>
              <button type="submit">
                <img src="../../../../assets/Svg/Search.svg" alt="" />
              </button>
              <input v-model="searchValue" aria-label="Search" id="search-input" type="search"
                placeholder="Search teammates" name="search" autocomplete="new-password" v-on:keydown.enter.prevent />
            </form>
          </div>
          <div class="menu-items" v-if="currentIsAdmin == '1' && currentTeamIsActive == 1">
            <div class="member-list" @click="downloadExcel()">
              <div class="member-detail">
                <div class="member-img">
                  <img src="../../../../assets/Svg/printer.svg" />
                </div>
                <span>Print Team Info</span>
              </div>
            </div>

            <div class="member-list" @click="inviteToTeamModalCall()">
              <div class="member-detail">
                <div class="member-img">
                  <img src="../../../../assets/Svg/team/invite-teammates.svg" alt="" />
                </div>
                <span>Invite To Team</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="teammates-filter-data-section">
        <div v-if="showFilterContainer" class="filter-container p-2" style="border-bottom: 1px solid #cce6fd"
          :class="['sticky-header-secondary', { scrolled: isScrolled }]" :style="{ top: dynamicTopValue }">
          <div class="filter-cotent">
            <div class="dropdown-wrapper" v-click-outside="closeDropdown">
              <button class="dropdown-button" :class="{ 'has-value': selectedRole }"
                @click.stop="toggleDropdown('role')">
                <span>{{ selectedRole || "Role" }}</span>
                <img :src="arrow_down" alt="arrow-down" />
              </button>
              <div class="dropdown-menu-content" v-show="activeDropdown === 'role'">
                <div v-for="role in roles" :key="role" class="dropdown-item" @click="selectRole(role)">
                  <span>{{ role }}</span>
                </div>
              </div>
            </div>
            <!-- Player Type Dropdown -->
            <button class="filter-button" :class="{ 'filter-button-active': isPlayerTrue }" :disabled="isPlayerFalse"
              @click="togglePastEvents" v-if="selectedRoomTeam.teamType == 'sports'">
              Player
            </button>
          </div>

          <button class="reset-button" @click="resetFilters" v-if="isResetVisible">
            Reset Filters
          </button>
        </div>

        <!-- Teammates list -->
        <div class="team-members">
          <div v-if="filteredUserProfile.length === 0">No Teammates Found.</div>

          <!-- Show if no teammates found after search -->
          <div v-else-if="filteredUserProfile.length === 0">
            No Teammates Found.
          </div>

          <div class="team-info" v-for="user in filteredUserProfile" :key="user.id">
            <div class="team-details border-0">
              <div class="team-image position-relative">
                <div class="users-notification" v-if="imgLoader == 1">
                  <img src="../../../../assets/loader.gif" alt="" />
                </div>
                <img @load="onImageLoad" :src="getUserImageUrl(user)" @error="handleImageError($event)" alt="" />
              </div>
              <div class="shirt-no" v-if="user?.isPlayer && selectedRoomTeam.teamType == 'sports'">
                <img v-if="user?.isPlayer" src="../../../../assets/Svg/shirt.svg" alt="Shirt Icon" />
                <span v-if="user?.isPlayer">
                  {{ user?.uniform_no ? user.uniform_no : "-" }}
                </span>
              </div>
              <div class="team-name-role">
                <h6 :id="user?.member?.user_link" class="d-flex align-items-center" style="gap: 5px;">
                  <router-link class="user-name mx-0" :to="'/profile/' + user?.member?.user_link">
                    {{ user?.member?.name }}
                  </router-link>
                  <span v-if="user?.user_profile?.nick_name" class="m-0">({{ user?.user_profile?.nick_name }} )</span>
                </h6>
                <span v-if="user.admin == '1'">Admin</span>
                <p v-if="user.admin == '0'"></p>
                <span v-if="user.fan == '1'">Fan</span>
              </div>
            </div>
            <MemberOptions :items="user" :selectedRoomTeam="selectedRoomTeam" :currentUserId="currentUserId"
              :currentIsAdmin="currentIsAdmin" :currentIsFan="currentIsFan" v-if="currentTeamIsActive == 1" />
          </div>
        </div>

        <TeamInviteList :selectedRoomTeam="selectedRoomTeam" :currentIsAdmin="currentIsAdmin" />
      </div>
    </div>
  </div>
</template>

<script>
import TeamInviteList from "../ChatSettingComponents/TeamInviteList.vue";
import MemberOptions from "../ChatSettingComponents/MemberOptions.vue";
import axios from "axios";
import InviteToTeamModal from "./InviteToTeamModal.vue";
import ExcelJS from "exceljs";

export default {
  name: "MembersInfos",
  data: function () {
    return {
      searchValue: "",
      $time: new Date().getTime(),
      $userProfileStorageURL:
        "https://api.whoifollow.tech/api/public/storage/users/",
      imgLoader: 1,
      teammates_id: [],
      userProfile: [],
      showLoader: 0,
      finalSelectedRoomTeam: [],
      arrow_down: require("../../../../assets/Svg/arrow-down.svg"),
      roles: ["Admin", "Fan", "Teammate"],

      activeDropdown: null,
      selectedRole: "",
      selectedType: true,
      isPlayerTrue: false,
      isPlayerFalse: false,
      isScrolled: false,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
    currentUserId: {
      type: String,
    },
  },
  components: {
    MemberOptions,
    InviteToTeamModal,
    TeamInviteList
  },
  watch: {
    "selectedRoomTeam.users": {
      handler(newUsers, oldUsers) {
        if (newUsers.length !== oldUsers.length) {
          // this.fetchTeammatesNickName();
        } else {
          this.updateUserNicknames();
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.selectedRoomTeam && Array.isArray(this.selectedRoomTeam.users)) {
      this.selectedRoomTeam.users.forEach((user) => {
        this.teammates_id.push(user.c_id);
      });
      // this.fetchTeammatesNickName();
    }
  },
  computed: {
    dynamicTopValue() {
      if (window.innerWidth < 768) {
        return this.currentIsAdmin == '1' && this.currentTeamIsActive == 1 ? '115px' : '69px';
      }
      return 'unset'; // Default for larger screens
    },
    showFilterContainer() {
      return /^\/my-team\/teammates\/[^/]+$/.test(this.$route.path);
    },
    isResetVisible() {
      return this.selectedRole !== "" || this.isPlayerTrue !== false;
    },
    filteredUserProfile() {
      if (!this.searchValue) {
        return this.userProfile; // Return all users if no search value
      }
      const searchTerm = this.searchValue.toLowerCase().trim();
      return this.userProfile.filter((user) => {
        const memberName = user?.member?.name?.toLowerCase() || "";
        return memberName.includes(searchTerm);
      });
    },
  },
  methods: {
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 100;
    },
    inviteToTeamModalCall() {
      this.$root.$emit("openInviteToTeamModal");
    },
    downloadExcel() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}user/usersListData`,
          this.selectedRoomTeam.users
        )
        .then((response) => {
          if (response.data && response.data.data) {
            this.printTeamInfoList = response.data.data;

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Team Information");

            // Add headers to the worksheet
            const headers = [
              "Name",
              "Email",
              "Phone",
              "Address",
              "Uniform No",
              "Bat Size",
              "Bat Load",
              "Hat Size",
              "Shirt Size",
              "Pant",
            ];
            worksheet.addRow(headers).eachCell((cell) => {
              cell.font = { bold: true };
            });

            // Add data to the worksheet
            this.printTeamInfoList.forEach((userInfo) => {
              const dataRow = [
                userInfo.username || "",
                userInfo.email || "",
                userInfo.phone || "",
                `${userInfo.address || ""} ${userInfo.city || ""} ${userInfo.state || ""
                } ${userInfo.zip_code || ""}`,
                userInfo.uniform || "",
                userInfo.bat_size || "",
                userInfo.bat_details || "",
                userInfo.hat_size || "",
                userInfo.shirt_size || "",
                userInfo.pant_size || "",
              ];
              worksheet.addRow(dataRow);
            });

            // Create a Blob from the workbook and download it
            workbook.xlsx.writeBuffer().then((buffer) => {
              const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });

              // Include today's date in the file name
              const today = new Date();
              const day = String(today.getDate()).padStart(2, "0");
              const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
              const year = today.getFullYear();

              const formattedDate = `${day}-${month}-${year}`;
              const filename = `${this.selectedRoomTeam.teamName}_EquipmentInfo_${formattedDate}.xlsx`;

              // Create a download link and trigger the download
              if (navigator.msSaveBlob) {
                // For IE
                navigator.msSaveBlob(blob, filename);
              } else {
                // For other browsers
                const link = document.createElement("a");
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
              }

            });
          } else {
            console.error("Invalid response from the server");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.isLoadingPrint = false;
        });
    },
    handleImageError(event) {
      event.target.src = "https://whoifollow.s3.us-west-2.amazonaws.com/chat/groupAvatar/LWE6E3CLzQZkgr56QyEmuCp8IHhut4ZddQa8qzFM.svg";
    },
    getUserImageUrl(user) {
      const imageUrl = user.user_id
        ? `${this.$userProfileStorageURL}${user.user_id}/profile_picture.jpg?v=${this.$time}`
        : "https://api.whoifollow.tech/user-default2.png";
      return imageUrl;
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    togglePastEvents() {
      if (this.isPlayerFalse) return; // Prevent unchecking when disabled
      this.isPlayerTrue = !this.isPlayerTrue;
      this.fetchTeamMembers();
    },
    toggleDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null; // Close if already open
      } else {
        this.activeDropdown = dropdownName; // Open the selected dropdown
      }
      console.log("Active Dropdown:", this.activeDropdown); // Debugging log
    },
    closeDropdown() {
      this.activeDropdown = null;
    },
    selectRole(role) {
      this.selectedRole = role;
      this.fetchTeamMembers();
      this.closeDropdown();
    },
    // selectType(type) {
    //   this.selectedType = ! this.selectedType;
    //   this.fetchTeamMembers();
    //   this.closeDropdown();
    // },
    resetFilters() {
      this.selectedRole = "";
      this.isPlayerFalse = false;
      this.isPlayerTrue = false;
      this.fetchTeamMembers();
    },
    async fetchTeamMembers() {
      this.showLoader = 1;

      // Create a new FormData instance
      const formData = new FormData();

      // Append the necessary data to the FormData object
      formData.append("teamId", this.selectedRoomTeam.id);
      formData.append("role", this.selectedRole);
      if (this.isPlayerTrue || this.isPlayerFalse) {
        formData.append("isPlayer", this.isPlayerTrue);
      } // Send 'true' if 'Player' is selected, else 'false'

      try {
        // Use axios with FormData to send a POST request
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}chat/getTeamMembers`,
          formData, // Pass the FormData object
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the correct header for FormData
            },
          }
        );

        if (response.data && response.data.data) {
          this.userProfile = response.data.data;
          this.$root.$emit("userProfileData", this.userProfile);
          this.showLoader = 0;
        } else {
          console.error(
            "Failed to fetch team members' data:",
            response.data.message
          );
          this.showLoader = 0;
        }
      } catch (error) {
        console.error("Error fetching team members' data:", error);
        this.showLoader = 0;
      }
    },
    updateUserNicknames() {
      this.finalSelectedRoomTeam = this.selectedRoomTeam.users.map((user) => {
        const userProfileData = this.userProfiles.find(
          (profile) => parseInt(profile.user_id) === parseInt(user.c_id)
        );
        if (userProfileData) {
          return {
            ...user,
            nick_name: userProfileData.nick_name,
            fname: userProfileData.fname,
            lname: userProfileData.lname,
          };
        } else {
          return user;
        }
      });
    },
  },
  mounted() {
    this.fetchTeamMembers();
    this.$root.$on("fetchUpdatedTeammates", () => {
      this.fetchTeamMembers();
    });
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style></style>